import React from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import Modal from 'react-modal';
import Layout from '../../layouts/layout'
var validator = require("email-validator");

// styles for modal
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

if (typeof window !== 'undefined') {
  var $ = require("jquery");
}

class TheNLPTimes extends React.Component {
  constructor() {
    super();
    this.state = {
      sButtonText: "Subscribe!",
      bEmailSubmitted: false,
      sEmail: '',
      iCount: '', 
      showModal: false
    }
    this.onClickSubscribe = this.onClickSubscribe.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    // retreive email count
    var that = this;
    // post to server
    if (typeof window !== 'undefined') {
     $.ajax({
        url: '/email_count',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        cache: false,
        timeout: 5000,
        success: function(oData) {
            that.setState({iCount: oData.count});
        },
        error: function() {
        }
      });
    }
  }
  onChangeInput(event) {
    console.log(event.target.value);
    this.setState({sEmail: event.target.value});
  }
  onClickSubscribe() {
    // First validate email
		console.log(this.state.sEmail);
    if (!validator.validate(this.state.sEmail)) {
      //notify.show('Please provide a valid email!', 'custom', 5000, notifyColor); // prompt the user for a proper email
      return; // end the process
    }

		var data = JSON.stringify({ email: this.state.sEmail });
    var that = this;
    // post to server
    if (typeof window !== 'undefined') {
     $.ajax({
        url: '/new_email',
        type: 'POST',
        dataType: 'json',
        data: data,
        contentType: 'application/json',
        cache: false,
        timeout: 5000,
        success: function(oData) {
          if (oData.bAlreadyHaveEmail) {
            that.setState({bEmailSubmitted: true, sButtonText: "You've Already Subscribed!", showModal: false});
          } else {
            that.setState({bEmailSubmitted: true, sButtonText: "Thank you!", showModal: false});
          }
          //notify.show('Thank you!', 'custom', 5000, notifyColor); // keep thank you forever
        },
        error: function() {
          that.setState({bEmailSubmitted: false, sButtonText: "Error! Try again?", showModal: false});
          //notify.show('Sorry, there was an error submitting your email. Care to try again?', 'custom', 5000, notifyColor); // prompt the user for a proper email
        }
      });
    }
  }
  onSubmitForm(event) {
    event.preventDefault(); // so enter doesn't reload the Page
    this.onClickSubscribe();
  }
  close() {
    this.setState({ showModal: false });
  }
  open() {
    console.log("in open!");
    this.setState({ showModal: true });
    console.log(this.state);
  }
  render() {
    return (
      <Layout>
        {/* Page Header */}
        <header className="masthead the_nlp_times">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1 className="newsletter--titles">The NLP Times</h1>
                  <span className="newsletter--titles">...because these are NLP times</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <h1><i>The NLP Times</i> - Monthly NLP Newsletter</h1>
              <p><i>The NLP Times</i> is a monthly newsletter handcrafted by us here at NLP Champs, showcasing the greatest posts of the month, news and development in NLP in the world at large, and anything else that we think is helpful to our readers, NLP hobbyists, or even stuff that is completely unrelated that we think is just plain cool.</p>
              <p>Join the <b>{this.state.iCount}</b> other NLP Champions who are already subscribed to <i>The NLP Times!</i></p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <Button bsStyle="success" className="btn" onClick={this.open} disabled={this.state.bEmailSubmitted}>{this.state.sButtonText}</Button>
            </div>
          </div>
        </div>
        {/* Modal */}
        <Modal isOpen={this.state.showModal} style={customStyles}>
            <p>Please enter your email:</p>
            <form onSubmit={this.onSubmitForm}>
              <FormGroup>
                <FormControl
                  type="email"
                  value={this.state.sEmail}
                  placeholder="i-am-an-awesome-nlpchamp@nlp-champs.com"
                  onChange={this.onChangeInput}
                />
                <FormControl.Feedback />
              </FormGroup>
            </form>
            <Button onClick={this.close}>Close</Button>
            <Button bsStyle="success" onClick={this.onClickSubscribe}>Confirm</Button>
        </Modal>
      </Layout>
    );
  }
}

export default TheNLPTimes;
